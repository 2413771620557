<script type="text/javascript"></script>

<template>

	<div>

		<TopBar :columnnumber = "2" hidedropdown simple>
			<template v-slot:title>
				Portfolios
			</template>
			
		</TopBar>

			<main class="main-wrapper cockpitview" style= "margin-top:21px;">

                <div class="cockpits">

                <div v-if="addingtype==''" class="w-50">

            	<div class="d-flex align-items-center">
				<div class="p-2 flex-grow-1" v-if="1==2">
					<b-dropdown id="generateoptions" text="Add a parameter" class="m-md-2" >

					<template #button-content>
						<i class="fas fa-plus-circle"></i> Add a parameter
					</template>

					<b-dropdown-item @click="initaddrow('input')">Text input</b-dropdown-item>
					<b-dropdown-item @click="initaddrow('selection')">Selection</b-dropdown-item>
					<b-dropdown-item v-if="1==2" @click="initaddrow('yesno')">Yes/No</b-dropdown-item>
					</b-dropdown>
				</div>
				<div class="p-2">

					<button v-if="1==2"  type="button" class="btn btn-sm btn-secondary mr-2" @click="cancelmode()">Cancel</button>

					<button v-if="rows.length>0" type="button" class="btn btn-primary btn-sm" @click="savesettings()">Done</button>
					<button v-else type="button" disabled="disabled" class="btn btn-primary btn-sm">Done</button>

				</div>
				</div>

				


				
                	

                </div>


				<div @click.stop.prevent="pageclick">


			<table class="table w-50" v-if="1==2">
			  <thead >
			    <tr style="position: sticky;top: 56px; background-color: #fff">
			      <th scope="col" style="width: 70%;">Name</th>
			      <th scope="col">Type</th>
			      <th scope="col" style="min-width: 100px;">Action</th>
			    </tr>
			  </thead>
			  <tbody>
			  	<template v-for="( row, key ) in rows"> 
			    <tr :key="'paramrow_'+key" class="paramrow">
			      <td>

			      	<template v-if="row.type!='phase'">

			      	<input v-if="editrowid==row._id" type="text" class="w-100 border-top-0 border-left-0 border-right-0 active-row" :value="row.name" @keydown="editinplacesave( $event, row )">
			  					<span v-else @click.stop.prevent="editrowid = row._id; editoptionid = ''">{{ row.name }}</span>

			  		</template>
			  		<template v-else>
			  			Project phases
			  		</template>
			      </td>
			      <td>
					<template v-if="row.size=='phase'"><span class="small">Phase</span></template>
			      	<template v-if="row.size=='small'"><span class="small">Text</span></template>
			      	<template v-if="row.size=='rich'"><span class="small">Multiline</span></template>
			      	<template v-if="row.size=='check'"><span class="small">Checkbox</span></template>
			      	<template v-if="row.size=='selection'"><span class="small">Dropdown</span></template>
			      	<template v-if="row.size=='big'"><span class="small">Big text</span></template>
			      </td>
			      <td nowrap="nowrap">
			      	
			      	<template v-if="row.type!='phase'">
			      	<a v-if="row.active" href="#" @click.stop.prevent="removeparameter(row)"><i class="fal fa-minus-circle"></i></a>
			  		<a v-else href="#" @click.stop.prevent="unremoveparameter(row)"><span v-if="!row.active" class="badge badge-simple bg-danger badge-clickable">Inactive</span></a>


			  		<span class="pl-2" v-if="row.size == 'selection' || row.size == 'option'">
			  					<a @click="setparameterasgroup(row, false)" v-if="row.setgroup"  href="#"><i class="fas fa-th-large"></i></a>
			  					<a @click="setparameterasgroup(row, true)" v-else href="#"><i class="fal fa-th-large"></i></a>
			  				</span>

			  		</template>
			      </td>
			    </tr>
			    <template v-if="row.type!='phase'">
			    <template v-for="( option, key2 ) in row.options" >
			    	<tr :key="'paramrowoptions_'+key+'_'+key2">			    		
			    		<td class="pl-5">

			    			<input v-if="editoptionid==option._id" @keydown="updateoptionname( $event, option, row )" type="text" class="w-100 border-top-0 border-left-0 border-right-0 active-row" :value="option.name">
					  				<span v-else class="flex-grow-1" @click.stop.prevent="editoptionid = option._id; editrowid = ''">{{ option.name }} <span class="small badge rounded-pill bg-danger hide">{{ option.sortorder }}</span>

					  				</span>

			    		</td>
			    		<td>&nbsp;</td>
			    		<td><a href="#" @click.stop.prevent="removeoption( row, option)"><i class="fal fa-times"></i></a></td>		    		
			    	</tr>
			    </template>
			   	<template v-if="row.type == 'selection'">
			    	<tr :key="'addnewparamrowoptions_'+key">
			    		<td colspan="3" class="pl-5">
			    			<input @keydown="addoption( $event, row )" placeholder="Add new option" title="Press enter to submit" type="text" class="w-100 border-top-0 border-left-0 border-right-0 active-row">
			    		</td>
			    	</tr>
			    </template>
			    </template>

			    <template v-if="row.type=='phase'">
			    <template v-for="( option, key2 ) in portfoliogroups" >
			    	<tr :key="'phase_'+key+'_'+key2">
			    		<td class="pl-5"> 
			    			<input v-if="editoptionid==option._id" @keydown="updatephasename( $event, option, row )" type="text" class="w-100 border-top-0 border-left-0 border-right-0 active-row" :value="option.name">
					  					<span v-else @click="editoptionid=option._id">{{ option.name }}</span>
			    		</td>
			    		<td>&nbsp;</td>
			    		<td><a href="#" @click.stop.prevent="removeoption( row, option)"><i class="fal fa-times"></i></a></td>		    		
			    	</tr>
			    </template>
			    <template v-if="row.type=='phase'">
			    	<tr :key="'addphase_'+key">
			    		<td colspan="3" class="pl-5">
			    			<input @keydown="addnewphase( $event )" placeholder="Add new phase" title="Press enter to submit" type="text" class="w-100 border-top-0 border-left-0 border-right-0 active-row">
			    		</td>
			    	</tr>

			    </template>
			    </template>

				</template>

				<tr>
					<td>						

			  				<span class="flex-grow-1 mr-2 ml-2 ">
			  					<input placeholder="Parameter name" type="text" class="w-100 border-top-0 border-left-0 border-right-0 active-row" v-model="addparametertext">
			  				</span>

			  		</td>
			  		<td>
			  			<select class="form-select" aria-label="Parameter type" v-model="addparametersize">
							  <option value="small">Single line text</option>
							  <option value="rich">Multiline</option>
							  <option value="big">Multi line text</option>
							  <option value="selection">Dropdown</option>
							  <option value="check">Checkboxes</option>
							</select>
			  		</td>

			  		<td>	

							<button class="ml-2 mr-2 btn btn-secondary btn-sm" @click.stop.prevent="addparameter">Add</button>

					</td>
				</tr>
			  </tbody>
			</table>



			<ul class="list-group w-50 p-2 parameterlist" id="parameterlist">

				
				<template v-for="( row, key ) in rows"> 

			  		<li :data-id="row._id"  class="list-group-item pl-0 pr-0 paramrow" :key="'paramrow_'+key" :class="{'inactive':!row.active}"> 

			  			<div class="d-flex align-items-center pr-2">

			  				<span class="grip m-2"><i class="fal fa-grip-vertical"></i></span>

			  			<template v-if="row.type!='phase'">
			  				<span class="flex-grow-1">
			  					<input v-if="editrowid==row._id" type="text" class="w-100 border-top-0 border-left-0 border-right-0 active-row" :value="row.name" @keydown="editinplacesave( $event, row )">
			  					<span v-else @click.stop.prevent="editrowid = row._id; editoptionid = ''">{{ row.name }}</span>


			  					<span class="small badge rounded-pill bg-danger hide">{{ row.sortorder }}</span>

			  					<template v-if="row.type=='selection' && editrowid!=row._id">

			  					<a v-if="expandlist==row._id" class="m-2" href="#" @click.stop.prevent="expandlist=''"><i class="fal fa-chevron-up"></i></a>
			  					<a v-else class="m-2" href="#" @click.stop.prevent="expandlist=row._id"><i class="fal fa-chevron-down"></i></a>

			  					</template>


			  					

			  				</span>			  				
			  			</template>
			  			<template v-else>
			  				<span class="flex-grow-1">Project phase <small class="m-1"><i v-b-tooltip.hover title="System parameter" class="fas fa-info-circle"></i></small> 

			  				<span v-if="editrowid!=row._id">

			  					<a v-if="expandlist==row._id" class="m-2" href="#" @click.stop.prevent="expandlist=''"><i class="fal fa-chevron-up"></i></a>
			  					<a v-else class="m-2" href="#" @click.stop.prevent="expandlist=row._id"><i class="fal fa-chevron-down"></i></a>

			  				</span>

			  				

			  				</span>

			  			</template>

			  			<div v-if="row.type!='phase'">

			  				<span class="pr-2" v-if="row.size == 'selection' || row.size == 'option'">
			  					<a @click="setparameterasgroup(row, false)" v-if="row.setgroup"  href="#"><i class="fas fa-th-large"></i></a>
			  					<a @click="setparameterasgroup(row, true)" v-else href="#"><i class="fal fa-th-large"></i></a>
			  				</span>


			  				<span class="pr-2" v-if="editrowid!=row._id">
			  					<a v-if="row.active" href="#" @click.stop.prevent="removeparameter(row)"><i class="fal fa-minus-circle"></i></a>
			  					<a v-else href="#" @click.stop.prevent="unremoveparameter(row)"><span v-if="!row.active" class="badge badge-simple bg-danger badge-clickable">Inactive</span></a>
			  				</span>

			  				<select v-if="editrowid!=row._id" style="width: 135px;" class="form-select" aria-label="Parameter type" @change="updateparametertype($event, row)">

								<template v-if="row.type=='input'">
								<option value="small" :selected="row.size=='small'">Text - single line</option>
								<option value="option" :selected="row.size=='rich'">Multiline</option>
								</template>

								<template v-if="row.type=='selection'">
								<option value="selection" :selected="row.size=='selection'">Dropdown</option>
								<option value="check" :selected="row.size=='check'">Checkboxes</option>
								<option value="option" :selected="row.size=='option'">Options</option>
								</template>

								<template v-if="row.type=='rich'">
								<option value="option" :selected="row.size=='rich'">Multiline</option>
								</template>	

								<template v-if="row.type=='file'">
								<option value="option" :selected="row.size=='file'">Document</option>
								</template>				  

							</select>

			  			</div>
			  			
			  			</div>

			  			<ul id="parameteroptions" class="list-group list-group-flush ml-2 mr-2" v-if="row.type == 'selection' && expandlist==row._id">

		  				<template v-for="( option, key2 ) in row.options">  
					  		<li :data-id="option._id" class="list-group-item pr-0 paramrowoption" :key="'paramrowoption_'+key2">
					  			<div class="d-flex align-items-center">

					  				<div class="ogrip m-2"><i class="fas fa-grip-vertical"></i></div>
					  				
					  				<input v-if="editoptionid==option._id" @keydown="updateoptionname( $event, option, row )" type="text" class="w-100 border-top-0 border-left-0 border-right-0 active-row" :value="option.name">
					  				<span v-else class="flex-grow-1" @click.stop.prevent="editoptionid = option._id; editrowid = ''">{{ option.name }} <span class="small badge rounded-pill bg-danger hide">{{ option.sortorder }}</span>

					  				</span>

					  				

					  				<span v-if="1==2" class="pr-2 badge">Used in 5 projects</span>
					  				<span class="pr-2"><a href="#" @click.stop.prevent="removeoption( row, option)"><i class="fal fa-times"></i></a></span>
					  			</div>
					  		</li>

					  	</template>
					  		<li :data-id="nullvalue" class="list-group-item pt-2 pb-2 pr-0 active-row" :key="'paramrowoptioninput_'">
					  			<div class="d-flex align-items-center">
					  				<input @keydown="addoption( $event, row )" placeholder="Add new option" title="Press enter to submit" type="text" class="w-100 border-top-0 border-left-0 border-right-0 active-row">
					  			</div>
					  		</li>
						</ul>

						<template v-if="row.type=='phase'">

						<ul id="phases" v-if="expandlist==row._id" class="list-group list-group-flush ml-2 mr-2">
		  				<template v-for="( option, key2 ) in portfoliogroups"> 
					  		<li :data-id="'phase_'+option._id" class="list-group-item pt-2 pb-2 pr-0 paramrowoption" :key="'paramrowoption_'+key2">
					  			<div class="d-flex align-items-center">

					  				<div class="ogrip m-2"><i class="fas fa-grip-vertical"></i></div>

					  				<span class="flex-grow-1">

					  					<input v-if="editoptionid==option._id" @keydown="updatephasename( $event, option, row )" type="text" class="w-100 border-top-0 border-left-0 border-right-0 active-row" :value="option.name">
					  					<span v-else @click="editoptionid=option._id">{{ option.name }}</span>
					  					
					  				</span>


					  				<span v-if="option.active && !option.default" class="pr-2"><a href="#" @click.stop.prevent="hidegroup( option, false)"><i class="fal fa-minus-circle"></i></a></span>

					  				<span v-if="!option.active" class="badge badge-simple badge-clickable bg-danger mr-2 ml-2" @click.stop.prevent="hidegroup( option, true)">Inactive</span>

					  				

					  			</div>
					  		</li>
					  	</template>
					  	<li :data-id="nullvalue" class="list-group-item pt-2 pb-2 pr-0 active-row" :key="'phaseinput_'">
					  			<div class="d-flex align-items-center">
					  				<input @keydown="addnewphase( $event )" placeholder="Add new phase" title="Press enter to submit" type="text" class="w-100 border-top-0 border-left-0 border-right-0 active-row">
					  			</div>
					  		</li>
						</ul>

						</template>
			  		</li>
			  		
			  	</template>

			  	<li  class="list-group-item p-2 active-row">
			  			<div class="d-flex align-items-center pr-2 pl-2">

			  				<i class="fal fa-plus-circle"></i>

			  				<span class="flex-grow-1 mr-2 ml-2 ">
			  					<input placeholder="Parameter name" type="text" class="w-100 border-top-0 border-left-0 border-right-0 active-row" v-model="addparametertext">
			  				</span>


			  				<select class="form-select" aria-label="Parameter type" v-model="addparametersize">
							  <option value="small">Single line text</option>
							  <option value="rich">Multiline</option>
							  <option value="big">Multi line text</option>
							  <option value="selection">Dropdown</option>
							  <option value="check">Checkboxes</option>
							</select>

							<button class="ml-2 mr-2 btn btn-secondary btn-sm" @click.stop.prevent="addparameter">Add</button>
			  			</div>
			  		</li>
			  		
			</ul>


	</div>

	</div>
	</main>
</div>
</template> 

<style type="text/css">

	.ghost {
	  opacity: 1;
	}

	.chosen{
		border-left: solid 3px;
		border-right: solid 3px;
	}

	.dragging{
		opacity: 1;
	}

	.paramrow .grip, .paramrowoption .ogrip{
		/*display: none;*/
		/*position: absolute;
		left: 5px;
		top: 8px;*/
	}

	.paramrow:hover .grip, .paramrowoption:hover .ogrip{
		display: block;
		cursor: move;
	}

	.inactive{
		/*background-color: var(--N100);*/
	}

	.badge-clickable{
		cursor: pointer !important;
	}

	.badge-simple{
		border-radius: 0 !important;
		margin: 2px;
	}

	.active-row{
		/*background-color: var(--N100) !important;*/
	}
	.parameter:hover{
		background-color: var(--N100);
	}

	.parameter .actions{
		background-color: #fff;
		padding: 5px;
		display: none;
		z-index: 99999;
	}

	.parameter:hover .actions{
		display: block;
	}

	.sortable-ghost { opacity: 0; }

</style>

<script>

	import {TopBar} from '@/components/topbar'

	import Portfolioapi from '@/services/api/portfolio'

	import Sortable from 'sortablejs';

	import { v4 as uuidv4 } from 'uuid'


	export default{
		name:'portfolioSettings',
		props:{},
		data(){
			return{
				nullvalue:'',
				withchanges:0,
				projectid:"",
				groupid:"",
				portfolioid:"",
				opensettingspage:false,
				portfolio:{},
				project:{},
				adding:false,
				addingtype:'',
				inputtextlabel:'',
				inputtextlabelsize:'small',
				inputselectionlabel:'',
				inputselectionoption:'',
				inputselectionoptions:[],
				inputselectionsize:'selection',
				editrow:{},
				editrowid:"",
				editoptionid:"",
				expandlist:"",
				editid:null,
				rows:[],
				sortable:null,
				editopts:[],
				editselectionrow:null,
				editgroup:null,
				portfoliogroups:[],
				addparametersize:"",
				addparametertext:"",
				sortableoptions:null,
				sortableparameterlist:null
			}
		},

		 mounted() {

		 	this.loadportfolio( true ) 
			
		 	
		 },

		watch:{

			expandlist( e ){

				let self = this

				if( e != '' ){

					setTimeout( function(){
						self.bindsortablesinner( e )
					}, 200)

					
				}
			},

			addingtype( e ){

				if( e == ''){
					this.loadrows()
				}else{
					//this.loadparameter()
				}
			},

			inputselectionsize(e){
				

				let self = this

				let cntr = 0
				this.rows.forEach( function( row ){
	          		if( self.editrow.uuid == row.uuid){

	          			console.log(self.rows[cntr].type)

	          			self.rows[cntr].type = e
	          		}

	          		cntr++
	          	})	
				//this.editrow.size = e
			},

			editselectionrow(e){			
				
				console.log( e )
			}

		},
		methods:{

			pageclick(){

				//this.editrowid = ""
				//this.editoptionid = ""
			},

			updateparametertype( select, parameter ){

				let size = select.target.value
				let type = "selection"

				if( size == "small" || size == "big" ){
					type = "input"
				}


				let update = JSON.stringify( { "size":size, "type":type } )

				Portfolioapi.updateParameter( { patch:update, parameterid:parameter._id, portfolioid:this.portfolioid, t:this.token, context:this.orgkey } ).then( function( result ){

					if( result.data.ok ){
						self.rows = result.data.parameters
					}
				})

			},

			setparameterasgroup( parameter, doset ){

				let self = this

				Portfolioapi.groupby( { "portfolioid":this.portfolioid, "parameterid":parameter._id, "doset":doset, "t":this.token } ).then( function( result ){

					if( result.data.ok ){
						self.rows = result.data.parameters
					}
				})

			},

			/*loadportfolioparameters(){


				let self = this

			 	this.portfolioid =  this.$route.params.portfolioid ? this.$route.params.portfolioid : ""

				Portfolioapi.getParameters( { pid:this.portfolioid, token:this.token, orgkey:this.orgkey }).then( function( result ){

					if( result.data.ok && result.data.portfolio ){
						self.portfolio = result.data.portfolio

						setTimeout( function(){
							self.loadrows()
						}, 50)

						self.portfoliogroups = self.portfolio.groups
					}
				})


			},*/

			loadportfolio( dorefresh, callback ){

				let self = this

			 	this.portfolioid =  this.$route.params.portfolioid ? this.$route.params.portfolioid : ""

				Portfolioapi.getOne( { pid:this.portfolioid, token:this.token, orgkey:this.orgkey }).then( function( result ){

					if( result.data.ok && result.data.portfolio ){

						if( dorefresh ){


							self.portfolio = result.data.portfolio

							setTimeout( function(){
								self.loadrows()
							}, 50)

							self.portfoliogroups = self.portfolio.groups

							setTimeout( function(){
								self.bindsortables()
								//self.bindsortablesgroups()
							}, 100)	


						}

						if( callback ){
							callback( result.data.portfolio )
						}
						
					}
				})

			},

			editgroupupdate( groupid, el ){

				//alert('bb')
				//return

				if( el.keyCode == 13 && el.target.value.length > 1 ){
					//this.portfoliogroups[key].name = el.target.value
					

					let self = this

					let update = JSON.stringify( { name:el.target.value } )

					Portfolioapi.updateGroup( { patch:update, gid:groupid, portfolioid:this.portfolioid, t:this.token, context:this.orgkey } ).then( function( result ){

						if( result.data.ok ){
							self.loadportfolio( true )
							self.editgroup = null
						}
					})


				}
			},

			editselectionrowupdate( key, el, optionid ){

				//console.log( this.inputselectionoptions[key], "key" )
				//return false
				if( el.keyCode == 13 ){

					let self = this

					if( this.editid ){

						let update = JSON.stringify( { name:el.target.value } )

						Portfolioapi.updateoption( { patch:update, parameterid:this.editid, optionid:optionid, t:this.token, context:this.orgkey } ).then( function( result ){

							if( result.data.parameter && result.data.parameter.options ){
								self.loadoptions( result.data.parameter.options )
								self.editselectionrow = null
							}

						})

					}else{

						this.inputselectionoptions[key].value = el.target.value
						this.editselectionrow = null

					}
				}
			},

			editinplacegroup( key, el ){

				this.editgroup = key	

				setTimeout( function(){		

					var editrow = document.getElementById( 'editgroup' + key);

					if( editrow ){					
						editrow.focus()					
					}

				}, 100)

			},

			updateoptionname( input, option, parameter ){

				if( input.keyCode != 13 ){
					return false
				}


				let self = this
				let update = JSON.stringify( { name:input.target.value } )

				Portfolioapi.updateoption( { patch:update, parameterid:parameter._id, optionid:option._id, t:this.token, context:this.orgkey } ).then( function( result ){

					if( result.data.parameter && result.data.parameter.options ){

						parameter.options = result.data.parameter.options
						self.editoptionid = ""
						//self.loadoptions( result.data.parameter.options )
						//self.editselectionrow = null
					}

				})


			},


			editinplacesave( input, parameter ){				

				if( input.keyCode == 27 ){
					
					this.editrowid = ""
					this.editoptionid = ""
					return false
				}


				if( input.keyCode != 13 ){
					return false
				}

				let self = this

				if( input.target.value != '' ){

					let update = JSON.stringify( { "name":input.target.value } )

					Portfolioapi.updateParameter( { patch:update, parameterid:parameter._id, portfolioid:this.portfolioid, t:this.token, context:this.orgkey } ).then( function( result ){

						if( result.data.ok ){
							parameter.name = result.data.parameter.name
							self.editrowid = ""
						}
					})

				}


				

			},

			editinplace( el ){

			},

			editinplace2( key, el ){

				this.editselectionrow = key	

				setTimeout( function(){		

					var editrow = document.getElementById( 'editselectionrow' + key);

					if( editrow ){					
							editrow.focus()			
						
					}

				}, 100)

			},

			bodyclick(){

				if( this.editselectionrow != null ){
					this.editselectionrow = null
				}

				if( this.editgroup != null ){
					this.editgroup = null
				}

			},

			hidegroup( group , isactive ){

				//group.active = group.active ? false : true

				let groupid = group._id
				let self = this

				Portfolioapi.activeGroup( { active:isactive, gid:groupid, portfolioid:this.portfolioid, t:this.token, context:this.orgkey } ).then( function( result ){

					if( result.data.ok ){
						self.loadportfolio( false, function( portfolio ){

							portfolio.groups.forEach( function( pgroup ){

								if( pgroup._id == groupid ){
									group.active = pgroup.active
								}

							})

							//self.portfoliogroups = portfolio.groups
						})
					}
				})

			},



			/*removeparameter( parameterid ){

				let self = this

				Portfolioapi.removeParameter( { parameterid:parameterid, portfolioid:this.portfolioid, token:this.token, context:this.orgkey } ).then( function( result ){

					if( result.data.ok ){
						self.loadportfolio()
					}
				})

			},*/

			removegroup( groupid ){

				let self = this

				Portfolioapi.deleteGroup( { gid:groupid, portfolioid:this.portfolioid, t:this.token, context:this.orgkey } ).then( function( result ){

					if( result.data.ok ){
						self.loadportfolio( true )
					}
				})

			},

			removeme( optionid ){	

				let self = this	

				if(this.editid){

					Portfolioapi.removeoption( { parameterid:this.editid, optionid:optionid, t:this.token, context:this.orgkey } ).then( function( result ){

							if( result.data.parameter && result.data.parameter.options ){
								self.loadoptions( result.data.parameter.options )
							}

						})

				}else{

					if( this.inputselectionoptions[optionid] ) { 
					 // this.editopts.splice(id, 1); 
					  this.inputselectionoptions.splice(optionid, 1); 
					 // this.withchanges++
					}


				}	

				

			},

		    bindsortablesinner( parameterid ){

		    	let self = this

		    	const parameteroptions = document.getElementById("parameteroptions");

		    	if( this.sortableoptions ){
		    		this.sortableoptions.destroy()
		    	}

		    	this.sortableoptions = new Sortable( parameteroptions, { handle:'.ogrip', ghostClass: "ghostClass", chosenClass: "shadow", dragClass: "dragging", onSort:function( obj ){

		    				let parameteroptionsvalue = self.sortableoptions.toArray()
		    				//console.log( parameteroptionsvalue )

							let update = JSON.stringify( { sortorder:parameteroptionsvalue, type:'options' } )

                            Portfolioapi.updatesort( { patch:update, portfolioid:self.portfolioid, t:self.token, context:self.orgkey } ).then( function( result ){
									//post sort action
								})

		    			} 
		    		})

		    },

		    bindsortables(){


		    	let self = this
		    	var parameterlist = document.getElementById('parameterlist');

		    	if( this.sortableparameterlist ){
		    		this.sortableparameterlist.destroy()
		    	}		    	
		    	
		    	this.sortableparameterlist = new Sortable( parameterlist, { handle:'.grip', ghostClass: "ghost", chosenClass: "chosen", dragClass: "dragging", onSort:function( obj ){

		    				let parameterlist = self.sortableparameterlist.toArray()

		    				let update = JSON.stringify( { sortorder:parameterlist, type:'parameters' } )

                            Portfolioapi.updatesort( { patch:update, portfolioid:self.portfolioid, t:self.token, context:self.orgkey } ).then( function( result ){
									//post sort action
								})	    				


				    	} 
				    })

		    	
		    },

		    loadparameter( item ){

		    	let self = this

				Portfolioapi.getParameter( { pid: this.portfolioid, parameterid: item, token:this.token, orgkey:this.orgkey }).then( function( result ){

					if( result.data.ok && result.data.parameter ){

						const row = result.data.parameter
						self.editid = row._id
						self.editrow = row
						self.addingtype = row.type
						self.inputtextlabel = row.name					
						self.inputtextlabelsize = row.size

						self.inputselectionlabel = row.name
						
						self.loadoptions( row.options )
						self.inputselectionsize = row.size

						setTimeout( function(){
							self.bindsortables()
							//self.bindsortablesgroups()
						}, 4000)	

					}
				})

		    },

		    loadoptions( options ){

		    	let self = this


		    	self.inputselectionoptions = []
		    	if( options && options.length > 0 ){

		    		for( var i =0; i<options.length;i++ ){
		    			let option = options[i]
		    			let opt = { "value":option.name, "uuid":option._id }
		    			self.inputselectionoptions.push(opt)
		    		}

		    		
		    	}

		    	
		    },

			edit( item ){

				this.loadparameter( item )	

			},
			move( direction, item ){

				let self = this

				if( direction == 'hide' ){
					if( !confirm('Hide this parameter') ){
						//removeparameter
						
						return false
					}
				}

				if( direction == 'hide' ){

					
					Portfolioapi.removeParameter( { pid:this.portfolioid, parameterid: item, token:this.token, orgkey:this.orgkey }).then( function( result ){

						if( result.data.ok && result.data.parameters ){

							self.rows = result.data.parameters

						}
					})

					return false
				}

				if( direction == 'show' ){

					
					Portfolioapi.undoremoveParameter( { pid:this.portfolioid, parameterid: item, token:this.token, orgkey:this.orgkey }).then( function( result ){

						if( result.data.ok && result.data.parameters ){

							self.rows = result.data.parameters

						}
					})

					return false
				}

				

				let cntr = 0

				let newrows = []
				this.rows.forEach( function( row ){					

					if( direction == 'hide' && item == cntr ){

					}else{
						newrows.push(row)
					}					

					cntr++

				})


				if( direction == 'up' && item > 0  ){
					let tmprow = newrows[item-1]
					newrows[item-1] = newrows[item]
					newrows[item] = tmprow
				}

				if( direction == 'down' && item != (this.rows.length-1)){
					let tmprow = newrows[item+1]
					newrows[item+1] = newrows[item]
					newrows[item] = tmprow
				}

				this.withchanges++

				this.rows = newrows

				
			},

			loadrows(){

				if( this.portfolio ){

					let self = this

					Portfolioapi.getParameters( { pid:this.portfolioid, token:this.token, orgkey:this.orgkey }).then( function( result ){

						if( result.data.ok && result.data.parameters ){

							self.rows = result.data.parameters

						}
					})

				}

			},

			cancelmode(){

				this.$router.push('detail')
				
			},

			savesettings( size ){

				/*let self = this

				if( this.rows.length>0 ){

					Portfolioapi.saveRows( {"portfolioid":this.portfolioid, "rows":JSON.stringify(this.rows), "token":this.toke, "size":size } ).then( function( result ){

						self.cancelmode()
					})

				}*/


				this.cancelmode()
			},

			updateinput(){

				let self = this

				if( this.editid != null ){

					let update = JSON.stringify( { name:this.inputtextlabel } )

					Portfolioapi.updateParameter( { patch:update, parameterid:this.editid, portfolioid:this.portfolioid, t:this.token, context:this.orgkey } ).then( function( result ){

						if( result.data.ok ){
							self.addingtype = ""
							self.inputtextlabel = ""
						}
					})


				}

			},

			updatephasename( input, phase ){

				if( input.keyCode != 13 || input.target.value == '' ){
					return false
				}


				let self = this

				let update = JSON.stringify( { name:input.target.value } )

				Portfolioapi.updateGroup( { patch:update, gid:phase._id, portfolioid:this.portfolioid, t:this.token, context:this.orgkey } ).then( function( result ){

					if( result.data.ok ){
						self.loadportfolio( true )
						input.target.value = ""
						self.editoptionid = ""
					}
				})

			},

			addnewphase( input ){

				if( input.keyCode != 13 || input.target.value == '' ){
					return false
				}

				let self = this
				//let newgroup = {"boards":[],"default":false,"name":input.target.value,"phase":{},"sortorder":0}				

				Portfolioapi.addNewGroup( { uuid:uuidv4(), name:input.target.value, portfolioid:this.portfolioid, t:this.token, context:this.orgkey } ).then( function( result ){
                        if( result.data.ok ){

                        	self.loadportfolio( true )

                        	input.target.value = ""
                        }
                    })

				//this.inputselectionoption = ""

			},

			removeparameter( parameter ){

				let self = this

				Portfolioapi.removeParameter( { pid:this.portfolioid, parameterid: parameter._id, token:this.token, orgkey:this.orgkey }).then( function( result ){

					if( result.data.ok && result.data.parameters ){

						self.rows = result.data.parameters

					}
				})


			},

			unremoveparameter( parameter ){

				let self = this

				Portfolioapi.undoremoveParameter( { pid:this.portfolioid, parameterid: parameter._id, token:this.token, orgkey:this.orgkey }).then( function( result ){

						if( result.data.ok && result.data.parameters ){

							self.rows = result.data.parameters

						}
					})


			},


			addparameter( el ){


				let self = this

				if( this.addparametertext != '' && this.addparametersize != '' ){

					let options = []

					let datatype = "input"

					if( this.addparametersize == 'selection' || this.addparametersize == 'check' || this.addparametersize == 'option' ){
						datatype = 'selection'
					}

					if( this.addparametersize == 'rich' ){
						datatype = 'rich'
					}

					if( this.addparametersize == 'file' ){
						datatype = 'file'
					}


					Portfolioapi.addParameter( { "portfolioid":this.portfolioid, "datatype":datatype, "name":this.addparametertext, "datasize":this.addparametersize, "sortorder":0, "active":true , "protected":false, "required":false, "options":JSON.stringify( options ), "t":this.token  } ).then( function( result ){

						el.target.value = ""
						self.addparametersize = ""
						self.addparametertext = ""
						self.loadrows()

					})

				}

				

			},

			addoption( input, parameter ){


				if( input.keyCode == 13  ){			// || forced	

					let self = this					

					if( input.target.value != '' ){

						Portfolioapi.addoption( { parameterid:parameter._id, name:input.target.value, t:this.token, context:this.orgkey } ).then( function( result ){

							if( result.data.parameter && result.data.parameter.options ){
								input.target.value = ""
								parameter.options = result.data.parameter.options
							}

						})
					}

			}


			},

			removeoption( parameter, option ){

				//return false

				Portfolioapi.removeoption( { parameterid:parameter._id, optionid:option._id, t:this.token, context:this.orgkey } ).then( function( result ){

							if( result.data.parameter && result.data.parameter.options ){
								//self.loadoptions( result.data.parameter.options )
								parameter.options = result.data.parameter.options
							}

						})
			},

			addinput(){

				if( this.inputtextlabel != '' ){

					let self = this

					let options = []

					Portfolioapi.addParameter( { "portfolioid":this.portfolioid, "datatype":"input", "name":this.inputtextlabel, "datasize":this.inputtextlabelsize, "sortorder":0, "active":true , "protected":false, "required":false, "options":JSON.stringify( options ), "t":this.token  } ).then( function( result ){

						self.addingtype = ""
						self.inputtextlabel = ""

					})
				}

			},

			updatephases( groups ){

				let self = this

				Portfolioapi.saveGroups( {"portfolioid":this.portfolioid, "groups":JSON.stringify(this.editopts), "t":this.token} ).then( function( result ){

					self.addingtype = ""
					//self.getportfolio(self.pid)
		 			//self.getboards()
		 			//self.getportfolio(self.pid)
		 			//self.opensettings = false
				})

			},


			updateselection(){

				if( this.inputselectionoption != "" ){
					this.inputselectionoptioninput( { keyCode : 13 }, true )
				}

				

				let self = this

				if( this.editid != null ){



					let update = JSON.stringify( { name:this.inputtextlabel, size:this.inputselectionsize } )

					Portfolioapi.updateParameter( { patch:update, parameterid:this.editid, portfolioid:this.portfolioid, t:this.token, context:this.orgkey } ).then( function( result ){

						if( result.data.ok ){
							self.addingtype = ""
							self.inputtextlabel = ""
						}
					})


				}

			},

			addselection(){



				if( this.inputselectionoption != "" ){
					this.inputselectionoptioninput( { keyCode : 13 }, true )
				}

				

				if( this.inputselectionlabel != '' && this.inputselectionoptions.length > 0 ){	

					let self = this

					let options = self.inputselectionoptions

					//JSON.stringify( options )

					Portfolioapi.addParameter( { "portfolioid":this.portfolioid, "datatype":"selection", "name":this.inputselectionlabel, "datasize":this.inputselectionsize, "sortorder":0, "active":true, "protected":false, "required":false, "options":JSON.stringify( options ) ,"t":this.token  } ).then( function( result ){

						self.addingtype = ""
						self.inputselectionlabel = ""
						self.inputselectionoptions = []
						self.inputselectionoption = ""
					})


				}
			},

			initaddrow( type ){

				this.inputtextlabel = ""
				this.inputselectionlabel = ""
				this.inputselectionoptions = []
				this.inputtextlabelsize = "small"
				this.inputselectionsize = "selection"
				this.addingtype = type

				this.editrow = {}
				this.editid = null

				this.editopts = []

				let self = this

				setTimeout( function(){
						//self.bindsortables()
					}, 100)

			},

			newphaseinput( e ){

				let self = this

				if( e.keyCode == 13 ){

					let newgroup = {"boards":[],"default":false,"name":this.inputselectionoption,"phase":{},"sortorder":0}				

					Portfolioapi.addNewGroup( { uuid:uuidv4(), name:this.inputselectionoption, portfolioid:this.portfolioid, t:this.token, context:this.orgkey } ).then( function( result ){
                            if( result.data.ok ){

                            	self.loadportfolio( true )
                            }
                        })

					this.inputselectionoption = ""

				}


			},

			inputselectionoptioninput( e, forced ){


				if( e.keyCode == 13 || forced ){				

					let self = this
					

					if( this.editid ){

						Portfolioapi.addoption( { parameterid:this.editid, name:this.inputselectionoption, t:this.token, context:this.orgkey } ).then( function( result ){

							if( result.data.parameter && result.data.parameter.options ){
								self.loadoptions( result.data.parameter.options )
							}

						})

					}else{

						let opt = { "uuid":uuidv4() , "value":this.inputselectionoption }
						this.inputselectionoptions.push( opt )					

					}

					this.inputselectionoption = ""

					//console.log( e )
					//inputselectionoptions 

					/*

					this.inputselectionoptions.push( opt )
					//this.editopts.push( this.inputselectionoption )
					this.inputselectionoption = ''

					this.editopts = this.inputselectionoptions*/
				}

			}

		},

		computed:{

			cockpitid(){
        		return this.$route.params.cid
     		},

			orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },	

			token(){
				return this.$store.getters.token
			},

      		cockpit(){
				return this.$store.getters.GetCockpit
			},

		},
		components:{ TopBar }
	}
	</script>